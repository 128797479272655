import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsGETApiAuth, AdsPOSTApi, AdsPUTApi } from "../../../../Constant/api";
import { slugs } from "../../../../Constant/slugs";
import { AlertComponent } from "../../../AlertComponent";
import { CustomButton } from "../../../CustomButtom";
import Database from '../../../../Constant/database.json'
import ImageUpload from "../../../ImageUpload";
import ExamQuestionModal from "./ExamQuestionModal";
import { CustomButtonUnSelect } from "../../../CustomButtomUnselect";
import ReactToPrint, { useReactToPrint } from 'react-to-print';

const QuestionBlock = [
  "question_number",
  "question",
  "question_description",
  "question_description_type",
  "question_media",
  "question_media_type",
  "option_1",
  "option_2",
  "option_3",
  "option_4",
  "answer_description",
  "correct_answer",
  "answer_media_type",
]

export default function FormComponent(props) {
  const { FetchData, db_slug, webSlug } = props;
  const history = useHistory()
  const [ID, setID] = React.useState(FetchData.id)

  const [Title, setTitle] = React.useState(FetchData.title.rendered)
  const [Time, setTime] = React.useState(FetchData.meta.exam_time)
  const [Details, setDetails] = React.useState(FetchData.meta.exam_details)
  const [Available, setAvaliable] = React.useState(FetchData.meta.available_unavailable)
  const [FeaturedImage, setFeaturedImage] = React.useState(FetchData.meta.featured_image)
  const [ExamType, setExamType] = React.useState(FetchData.meta.exam_type)
  const [CopyTo, setCopyTo] = React.useState("")
  const [AudioSingle, setAudioSingle] = React.useState(FetchData.meta.media_non_repeat)

  const [QuestionsBlock, setQuestionsBlock] = React.useState(true);
  const [QuestionsData, setQuestionsData] = React.useState(
    db_slug == "demo_exam" ?
      JSON.parse(FetchData.meta.exam_question_text) :
      JSON.parse(FetchData.meta.exam_question_block_text)
  );
  const [QuestionsEditMode, setQuestionsEditMode] = React.useState(false);
  const [QuestionsEditData, setQuestionsEditData] = React.useState({});
  const [QuestionsEditKey, setQuestionsEditKey] = React.useState();

  const [reload, setReload] = React.useState(false)

  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")

  const EditInfo = (POSTID, slug, passSlug) => {
    setReload(true)
    console.log(QuestionsData)
    var data;
    if (slug == "daily_exam")
      data = {
        "title": Title,
        "status": "publish",
        "meta": {
          "exam_question_block_text": JSON.stringify(QuestionsData),
          "exam_time": Time,
          "batch_id": batchID,
          "batch_name": batchName,
          "exam_details": Details,
          "available_unavailable": Available,
          "featured_image": FeaturedImage,
          "media_non_repeat": AudioSingle,
          "exam_type": ExamType
        }
      }
    else if (slug == "demo_exam")
      data = {
        "title": Title,
        "status": "publish",
        "meta": {
          "exam_question_text": JSON.stringify(QuestionsData),
          "exam_time": Time,
          "exam_details": Details,
          "available_unavailable": Available,
          "featured_image": FeaturedImage,
          "media_non_repeat": AudioSingle,
          "exam_type": ExamType
        }
      }
    else
      data = {
        "title": Title,
        "status": "publish",
        "meta": {
          "exam_question_block_text": JSON.stringify(QuestionsData),
          "exam_time": Time,
          "exam_details": Details,
          "available_unavailable": Available,
          "featured_image": FeaturedImage,
          "media_non_repeat": AudioSingle,
          "exam_type": ExamType,
          "exam_price": ExamPrice
        }
      }

    if (POSTID === "")
      AdsPOSTApi(data, slug)
        .then((res) => {
          setReload(false)
          setID(res.data.id)
          setAlertShow(true)
          setAlertcomp("Exam Added Successfully")
          console.log(res.data)
          history.push(`${passSlug}${res.data.slug}`)
        }).catch(err => {
          setReload(false)
          console.log(err)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
    else
      AdsPUTApi(data, slug, POSTID)
        .then((res) => {
          setReload(false)
          setAlertShow(true)
          setAlertcomp(`${Title} edited Successfully`)
        }).catch(err => {
          console.log(err)
          console.warn(err.response.data)
          console.log("Response Status: hello11", err.response.status);
          console.log("Response Headers:", err.response.headers);
          setReload(false)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
  }
  const [batchID, setBatchID] = React.useState(FetchData.meta.batch_id)
  const [batchName, setBatchName] = React.useState(FetchData.meta.batch_name)
  const [batchModal, setBatchModal] = React.useState(false)
  const [batchList, setBatchList] = React.useState([])
  const [ExamPrice, setExamPrice] = React.useState(db_slug == slugs.db_slug_paid_exam ? FetchData.meta.exam_price : "")
  useEffect(() => {
    if (db_slug == "daily_exam")
      BatchList()
  }, [])
  const BatchList = () => {
    AdsGETApiAuth(
      { per_page: 100, _fields: "id,title,meta.batch_status,meta.batch_start_date,meta.batch_end_date" },
      slugs.db_slug_batch
    )
      .then((res) => {
        setBatchList(res.data)
      })
      .catch(e => {
        console.log(e)
      })
  }

  const StudentExportRef = React.useRef(null);
  const StudentExportPrint = useReactToPrint({
    content: () => StudentExportRef.current,
    pageStyle: { margin: 0 }

  })
  const StudentExportRef1 = React.useRef(null);
  const StudentExportPrint1 = useReactToPrint({
    content: () => StudentExportRef1.current,
    pageStyle: { margin: 0 }

  })
  return (
    <div>
      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Exam Information</center></h4>

          <table>
            <tbody>
              <tr>
                <td className="col-3">Exam Name</td>
                <td className="col-6">
                  <input className="input-common"
                    value={Title} placeholder="Name"
                    onChange={(event) => { setTitle(event.target.value) }}
                  />
                </td>
              </tr>

              <tr>
                <td className="col-3">Exam Time (in minutes)</td>
                <td className="col-6">
                  <input className="input-common"
                    value={Time} placeholder="Exam Time"
                    onChange={(event) => { setTime(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Exam Details</td>
                <td className="col-6">
                  <textarea rows={5} className="input-common"
                    value={Details} placeholder="Exam Details"
                    onChange={(event) => { setDetails(event.target.value) }}
                  />
                </td>
              </tr>
              {db_slug !== "daily_exam" &&
                <>
                  <tr>
                    <td className="col-3">Exam Price</td>
                    <td className="col-6">
                      <input className="input-common"
                        value={ExamPrice} placeholder="Exam Price"
                        onChange={(event) => { setExamPrice(event.target.value) }}
                      />
                    </td>
                  </tr>
                  {/* <tr>
                    <td className="col-3">Exam Reset Price</td>
                    <td className="col-6">
                      <input className="input-common"
                        value={ExamResetPrice} placeholder="Exam Reset Price"
                        onChange={(event) => { setExamResetPrice(event.target.value) }}
                      />
                    </td>
                  </tr> */}
                </>
              }
              <tr>
                <td className="col-3">Featured Image</td>
                <td className="col-6">
                  <ImageUpload
                    ImageUrl={FeaturedImage}
                    setImageUrl={setFeaturedImage}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Audio Single</td>
                <td className="col-6">
                  <div style={{ display: "inline" }}>
                    <div style={{ display: "inline-block" }}>
                      <CustomButtonUnSelect setData={setAudioSingle} Data={AudioSingle} DataValue={"Single"} />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="col-3">Exam Name</td>
                <td className="col-6">
                  <CustomButton setData={setAvaliable} Data={Available} DataValue={"Available"} />
                  <CustomButton setData={setAvaliable} Data={Available} DataValue={"Unavailable"} />
                </td>
              </tr>
              <tr>
                <td className="col-3">Exam Type</td>
                <td className="col-6">
                  <div style={{ display: "inline" }}>
                    {Database.exam_type.map((item, index) => (
                      <div style={{ display: "inline-block" }} key={index}>
                        <CustomButton setData={setExamType} Data={ExamType} DataValue={item} />
                      </div>
                    ))}
                  </div>
                </td>
              </tr>

            </tbody>
          </table>
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  EditInfo(ID, db_slug, webSlug)
                }}
              >
                Update Exam
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-1"></div>
        <div className="col-10 form-view mb-3">
          <h4><center>Questions</center></h4>
          <div style={{ display: "inline", justifyContent: "flex-end" }}>
            <div style={{ cursor: "pointer", display: "inline-block", margin: "5px", color: QuestionsBlock ? "#000000" : "#444444", fontWeight: QuestionsBlock ? "bold" : "normal" }}
              onClick={() => {
                setQuestionsBlock(true)
              }}
            >
              Block
            </div>
            <div style={{ cursor: "pointer", display: "inline-block", margin: "5px", color: !QuestionsBlock ? "#000000" : "#444444", fontWeight: !QuestionsBlock ? "bold" : "normal" }}
              onClick={() => {
                setQuestionsBlock(false)
              }}
            >
              Table
            </div>
          </div>
          {(db_slug == slugs.db_slug_daily_exam || db_slug == slugs.db_slug_chapter_wise_exam) &&
            <div>
              <div className="row center m-2">
                <div className=" col-3" style={{ padding: "0 10px" }}>
                  <div className="sign-in-button-4"
                    onClick={() => {
                      setQuestionsEditData({
                        "question_number": `${Object.keys(QuestionsData).length + 1}`,
                        "question": "",
                        "question_description": "",
                        "question_description_type": "text",
                        "question_media": "",
                        "question_media_type": "none",
                        "option_1": "",
                        "option_2": "",
                        "option_3": "",
                        "option_4": "",
                        "answer_description": "",
                        "correct_answer": "option 1",
                        "answer_media_type": "text",
                      })
                      setQuestionsEditKey(`item-${Object.keys(QuestionsData).length}`)
                      setQuestionsEditMode(true)
                    }}
                  >
                    Add Question
                  </div>
                </div>
              </div>
            </div>
          }
          {QuestionsBlock ?
            <div>
              <div className="row m-4">
                <div className="col-6">
                  <div className="row">
                    {Object.values(QuestionsData).map((item, index) => {
                      if (index < Object.values(QuestionsData).length / 2)
                        return (
                          <div key={index} className="col-2 p-2 center"
                            style={{
                              margin: "5px",
                              backgroundColor: item.question !== "" ? "#fad4d4" : "#fafad4",
                              fontWeight: "bold",
                              border: "2px solid black",
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setQuestionsEditData(item)
                              setQuestionsEditKey(Object.keys(QuestionsData)[index])
                              setQuestionsEditMode(true)
                            }}
                          >
                            {item.question_number}
                          </div>
                        )
                    })}
                  </div>
                </div>
                <div className="col-6">
                  <div className="row">
                    {Object.values(QuestionsData).map((item, index) => {
                      if (index >= Object.values(QuestionsData).length / 2)
                        return (
                          <div key={index} className="col-2 p-2 center"
                            style={{
                              margin: "5px",
                              backgroundColor: item.question !== "" ? "#fad4d4" : "#fafad4",
                              fontWeight: "bold",
                              border: "2px solid black",
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setQuestionsEditData(item)
                              setQuestionsEditKey(Object.keys(QuestionsData)[index])
                              setQuestionsEditMode(true)
                            }}
                          >
                            {item.question_number}
                          </div>
                        )
                    })}
                  </div>
                </div>
              </div>
            </div>
            :
            <div style={{
              margin: "20px",
              // display: "none",
            }}
            >
              <div style={{ margin: "20px" }}>
                <div className="col-12 form-view mb-3 ques-print" style={{ padding: "20px" }}>
                  <div style={{ overflowX: "scroll", }}>
                    {Object.values(QuestionsData).map((oneQuestionData, index) => (
                      <section key={index} style={{
                        pageBreakInside: "avoid", display: "block", cursor: "pointer", padding: "3px 0",
                      }}
                        onClick={() => {
                          setQuestionsEditData(oneQuestionData)
                          setQuestionsEditKey(Object.keys(QuestionsData)[index])
                          setQuestionsEditMode(true)
                        }}
                      >
                        <div>
                          <div className="row"
                            style={{
                              border: "1px solid #999",
                              borderRadius: "5px",
                              margin: "5px",
                              padding: 0
                            }}>
                            <div className="col-md-8" >
                              <div>
                                <div style={{ padding: "5px 0 0 0" }}>
                                  <h3 style={{ fontSize: "18px", fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                                    {oneQuestionData.question}
                                  </h3>
                                  <h3 style={{ fontSize: "18px", fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                                    {oneQuestionData.question_number}.
                                  </h3>
                                </div>
                                <div className="question-description ">
                                  {oneQuestionData.question_description_type == "text" &&
                                    <div style={{
                                      fontSize: "15px",
                                      padding: "0 0 15px"
                                    }}>
                                      <div dangerouslySetInnerHTML={{ __html: oneQuestionData.question_description.replaceAll('\n', '<br/>') }}></div>
                                    </div>
                                  }
                                  {oneQuestionData.question_description_type == "image" &&
                                    <>
                                      <div style={{ padding: "2px", textAlign: "center", }} >
                                        <img
                                          src={oneQuestionData.question_description}
                                          style={{
                                            height: "150px",
                                            width: "392px",
                                            objectFit: 'contain',
                                            padding: "2px",
                                            border: "0.5px solid #999",
                                          }}
                                        />
                                      </div>
                                    </>
                                  }
                                  {oneQuestionData.question_description_type == "audio" &&
                                    <div>
                                      <audio controls>
                                        {/* <source src={`${ImageUrl}`} type="audio/ogg" /> */}
                                        <source src={`${oneQuestionData.question_description}`} type="audio/mpeg" />
                                        <source src={`${oneQuestionData.question_description}`} type="audio/mp3" />
                                        <source src={`${oneQuestionData.question_description}`} type="audio/wav" />
                                        <source src={`${oneQuestionData.question_description}`} type="audio/ogg" />
                                        Your browser does not support the audio element.
                                      </audio>
                                      {/* <a href={oneQuestionData.question_description} target="_blank">
                                        <div style={{ cursor: "pointer" }} onClick={() => {
                                        }}>
                                          <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                            style={{ width: "20px", height: "20px" }}
                                          />
                                        </div>
                                      </a> */}
                                    </div>
                                  }
                                </div>


                                {oneQuestionData.question_media !== "" &&
                                  <div className="question-description col-10">
                                    {oneQuestionData.question_media_type == "text" &&
                                      <div style={{
                                        border: "0.5px solid #999",
                                        padding: "5px ",
                                        justifyContent: 'center',
                                        display: "flex"
                                      }}>
                                        <div style={{ fontSize: "16px" }} dangerouslySetInnerHTML={{ __html: oneQuestionData.question_media.replaceAll('\n', '<br/>') }}></div>
                                      </div>
                                    }
                                    {oneQuestionData.question_media_type == "image" &&
                                      <div style={{ textAlign: "center" }}
                                        onClick={() => {
                                        }}
                                      >

                                        <img
                                          src={oneQuestionData.question_media}
                                          style={{
                                            height: "150px",
                                            width: "392px",
                                            padding: "2px",
                                            objectFit: 'contain',
                                            border: "0.5px solid #999"
                                          }}
                                        />
                                      </div>
                                    }
                                    {oneQuestionData.question_media_type == "audio" &&
                                      <div style={{ cursor: "pointer" }} onClick={() => {
                                      }}>
                                        <audio controls>
                                          {/* <source src={`${ImageUrl}`} type="audio/ogg" /> */}
                                          <source src={`${oneQuestionData.question_media}`} type="audio/mpeg" />
                                          <source src={`${oneQuestionData.question_media}`} type="audio/mp3" />
                                          <source src={`${oneQuestionData.question_media}`} type="audio/wav" />
                                          <source src={`${oneQuestionData.question_media}`} type="audio/ogg" />
                                          Your browser does not support the audio element.
                                        </audio>
                                        {/* <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                style={{ width: "20px", height: "20px" }}
                              /> */}
                                      </div>
                                    }
                                  </div>
                                }
                              </div>
                            </div>
                            <div className={oneQuestionData.answer_media_type == "image" ? "col-md-12 ms-5" : "col-md-4"}
                              style={{ padding: "0" }}>
                              <div
                                className={oneQuestionData.answer_media_type == "image" ? "row" : ""}
                                style={{
                                }} >
                                <AnswerBlock index={1} DataValue={oneQuestionData.option_1} DataType={oneQuestionData.answer_media_type} correct_answer={oneQuestionData.correct_answer} option={"option 1"} />
                                <AnswerBlock index={2} DataValue={oneQuestionData.option_2} DataType={oneQuestionData.answer_media_type} correct_answer={oneQuestionData.correct_answer} option={"option 2"} />
                                <AnswerBlock index={3} DataValue={oneQuestionData.option_3} DataType={oneQuestionData.answer_media_type} correct_answer={oneQuestionData.correct_answer} option={"option 3"} />
                                <AnswerBlock index={4} DataValue={oneQuestionData.option_4} DataType={oneQuestionData.answer_media_type} correct_answer={oneQuestionData.correct_answer} option={"option 4"} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    ))}
                  </div>
                </div>
              </div>
            </div>

          }
        </div>
      </div>

      {
        QuestionsEditMode &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setQuestionsEditMode(false)
                    setQuestionsEditData([])
                    setQuestionsEditKey("")
                  }}
                >X</div>
                <ExamQuestionModal
                  oneQuestionData={QuestionsEditData}
                  keyValue={QuestionsEditKey}
                  AllQuestionData={QuestionsData}
                  setAllQuestionData={setQuestionsData}
                  PostExam={EditInfo}
                  setEditQuestion={setQuestionsEditMode}
                />
              </div>
            </div>
          </div>
        </section>
      }


      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Print:</center></h4>

          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  StudentExportPrint()
                }}
              >
                Convert to pdf
              </div>
            </div>
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  StudentExportPrint1()
                }}
              >
                Convert to answer
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{ display: "none" }}
      >
        <div ref={StudentExportRef}>
          <div>
            <div id="content">
              <div className="col-12 ques-print"
              >
                <div style={{
                  zIndex: 1, position: "absolute", height: "100%",
                }}>
                  <img src={"https://api.jnsirkoreanlanguage.com/wp-content/uploads/2024/02/watermarkjn.jpeg"}
                    style={{
                      width: "100vw",
                      height: "100vh",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <div style={{fontSize:25,fontWeight:"bold",textAlign:"center"}}>{Title}</div>
                <div style={{ overflowX: "scroll", }}>
                  {Object.values(QuestionsData).map((oneQuestionData, index) => {

                    const lower = oneQuestionData.question.split(" ")[0].split("~")[0].replace(/\D/g, "");
                    // const lower = oneQuestionData.question
                    return (
                      <section key={index} style={{
                        pageBreakInside: "avoid", display: "block", cursor: "pointer", padding: "3px 0",
                      }}
                        onClick={() => {
                          setQuestionsEditData(oneQuestionData)
                          setQuestionsEditKey(Object.keys(QuestionsData)[index])
                          setQuestionsEditMode(true)
                        }}
                      >
                        <div style={{
                          zIndex: 1, position: "absolute", height: "100%",
                        }}>
                          <img src={"https://api.jnsirkoreanlanguage.com/wp-content/uploads/2024/02/watermarkjn.jpeg"}
                            style={{
                              width: "100vw",
                              height: "100vh",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                        <div style={{ zIndex: 10, position: "relative", }}>
                          <div style={{ padding: "0px 0 0 0" }}>
                            {oneQuestionData.question_number == "1" &&
                              <h3 style={{ textAlign: "center", fontSize: "18px", fontWeight: "bold", margin: 0, marginBottom: 10, paddingBottom: 10,textDecorationLine:"underline" }}>
                                읽기 ( 20 문항 )
                              </h3>
                            }
                            {oneQuestionData.question_number == 21 &&
                              <h3 style={{ textAlign: "center", fontSize: "18px", fontWeight: "bold", margin: 0, marginBottom: 10, paddingBottom: 10, textDecorationLine:"underline" }}>
                                듣기 ( 20 문항 )
                              </h3>
                            }
                            {lower == oneQuestionData.question_number &&
                              <h3 style={{ fontSize: "18px", fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                                {oneQuestionData.question}
                              </h3>
                            }
                            <div style={{ display: "inline" }}>
                              <div style={{ display: "inline-block", fontSize: "16px", margin: "0 10px 0 30px", paddingBottom: 0 }}>
                                {oneQuestionData.question_number}.
                              </div>
                              {oneQuestionData.question_description_type == "text" &&
                                <div style={{
                                  display: "inline-block",
                                  fontSize: "20px",
                                  padding: "0 0 5px", margin: "0 0px"
                                }}>
                                  <div style={{ fontSize: "15px" }} dangerouslySetInnerHTML={{ __html: oneQuestionData.question_description.replaceAll('\n', '<br/>') }}></div>
                                </div>
                              }
                            </div>
                          </div>
                          <div className="row"
                            style={{
                              // border: "1px solid #999",
                              borderRadius: "5px",
                              // margin: "5px",
                              padding: 0
                            }}>
                            {oneQuestionData.question_description_type == "image" || oneQuestionData.question_media_type == "image" ?
                              <div className={
                                (oneQuestionData.option_1.length < 17 &&
                                  oneQuestionData.option_2.length < 17 &&
                                  oneQuestionData.option_3.length < 17 &&
                                  oneQuestionData.option_4.length < 17) ?
                                  "col-sm-6"
                                  :
                                  "col-sm-12"
                              } >
                                <div>

                                  <div>
                                    {oneQuestionData.question_description_type == "image" &&
                                      <>
                                        <div style={{ padding: "2px", textAlign: "center", }} >

                                          <img
                                            src={oneQuestionData.question_description}
                                            style={{
                                              height: "120px",
                                              objectFit: 'contain',
                                              padding: "2px",
                                              textAlign: "center"
                                              // border: "0.5px solid #999",
                                            }}
                                          />
                                        </div>
                                      </>
                                    }
                                  </div>

                                  {oneQuestionData.question_media !== "" &&
                                    <div className="col-12">
                                      {oneQuestionData.question_media_type == "text" &&
                                        <div style={{
                                          border: "0.5px solid #555",
                                          padding: "2px 5px",
                                          justifyContent: 'center',
                                          display: "flex"
                                        }}>
                                          <div style={{ fontSize: "15px" }} dangerouslySetInnerHTML={{ __html: oneQuestionData.question_media.replaceAll('\n', '<br/>') }}></div>
                                        </div>
                                      }
                                      {oneQuestionData.question_media_type == "image" &&
                                        <div style={{ textAlign: "center" }}>
                                          <center>
                                            <img
                                              src={oneQuestionData.question_media}
                                              style={{
                                                height: "120px",
                                                padding: "2px",
                                                objectFit: 'contain',
                                                // border: "0.5px solid #999"
                                                textAlign: "center"
                                              }}
                                            />
                                          </center>
                                        </div>
                                      }
                                    </div>
                                  }
                                </div>
                              </div>
                              :
                              <div className="col-md-12" >
                                <div style={{}}>
                                  <div>
                                    {oneQuestionData.question_description_type == "image" &&
                                      <>
                                        <div style={{ padding: "2px", textAlign: "center", }}>
                                          <img
                                            src={oneQuestionData.question_description}
                                            style={{ height: "70px", objectFit: 'contain', padding: "2px", border: "0.5px solid #000", }}
                                          />
                                        </div>
                                      </>
                                    }
                                  </div>
                                  {oneQuestionData.question_media !== "" &&
                                    <div style={{ margin: "0 40px", width: "90%" }}>
                                      {oneQuestionData.question_media_type == "text" &&
                                        <div style={{ border: "0.5px solid #555", padding: "2px 5px ", margin: "5px", justifyContent: 'center', display: "flex" }}>
                                          <div style={{ textAlign: "center", fontSize: "15px" }} dangerouslySetInnerHTML={{ __html: oneQuestionData.question_media.replaceAll('\n', '<br/>') }}></div>
                                        </div>
                                      }

                                      {oneQuestionData.question_media_type == "image" &&
                                        <div style={{ textAlign: "center" }}  >
                                          <img
                                            src={oneQuestionData.question_media}
                                            style={{ height: "100px", padding: "2px", objectFit: 'contain', border: "0.5px solid #000" }}
                                          />
                                        </div>
                                      }
                                    </div>
                                  }
                                </div>
                              </div>
                            }
                            <div
                              className={
                                oneQuestionData.answer_media_type == "audio" ?
                                  "col-sm-12 ms-5" :
                                  oneQuestionData.answer_media_type == "image" ?
                                    "col-sm-12 ms-5" :
                                    oneQuestionData.question_description_type == "image" || oneQuestionData.question_media_type == "image" ?
                                      (oneQuestionData.option_1.length < 15 &&
                                        oneQuestionData.option_2.length < 15 &&
                                        oneQuestionData.option_3.length < 15 &&
                                        oneQuestionData.option_4.length < 15) ?
                                        "col-sm-6"
                                        :
                                        oneQuestionData.option_1.includes("https://")
                                          ? "col-sm-6" : "col-sm-12 ms-5"

                                      : "col-sm-12"
                              }
                              style={{ padding: "0", margin: "auto" }}>
                              <div
                                className={
                                  oneQuestionData.answer_media_type == "audio" ? "row" :
                                    oneQuestionData.answer_media_type == "image" ? "row" :
                                      oneQuestionData.question_description_type == "image" || oneQuestionData.question_media_type == "image" ?
                                        "" : "row"}
                                style={{
                                  justifyContent: "center", alignContent: "center", alignItems: "center", height: "100%",
                                  margin: oneQuestionData.question_description_type == "image" || oneQuestionData.question_media_type == "image" ? 0 : "0 50px"
                                }} >
                                <AnswerBlock7 correct_answer={oneQuestionData.correct_answer} option={"option 1"}
                                  oneQuestionData={oneQuestionData}
                                  index={1} DataValue={oneQuestionData.option_1} DataType={oneQuestionData.answer_media_type}
                                />
                                <AnswerBlock7
                                  correct_answer={oneQuestionData.correct_answer} option={"option 2"}
                                  oneQuestionData={oneQuestionData} index={2} DataValue={oneQuestionData.option_2} DataType={oneQuestionData.answer_media_type} />
                                <AnswerBlock7
                                  correct_answer={oneQuestionData.correct_answer} option={"option 3"} oneQuestionData={oneQuestionData} index={3} DataValue={oneQuestionData.option_3} DataType={oneQuestionData.answer_media_type}
                                />
                                <AnswerBlock7 correct_answer={oneQuestionData.correct_answer} option={"option 4"} oneQuestionData={oneQuestionData} index={4} DataValue={oneQuestionData.option_4} DataType={oneQuestionData.answer_media_type}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    )
                  })}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <div
          ref={StudentExportRef1}
          style={{ zIndex: 10, position: "relative" }}>
          <div style={{ pageBreakInside: "avoid", display: "block", cursor: "pointer", backgroundColor: "#fff", padding: "3px 0" }}>
            <br />
            <u><b>Answers</b></u>
            <br />
            <div style={{ border: "2px solid black", padding: "0px 10px", width: "90%", marginLeft: "10px" }}>
              <div className="row" >
                {Object.values(QuestionsData).map((oneQuestionData, index) => (
                  <section key={index} style={{ width: "20%", border: "1px solid black", }}>
                    <div className="row">
                      <div className="col-6" style={{
                        borderRight: "1px solid black", textAlign: "center", padding: "10px 0", fontWeight: "bold"
                      }} >{oneQuestionData.question_number}</div>
                      <div className="col-6" style={{ padding: "10px 0", textAlign: "center" }}>{oneQuestionData.correct_answer.replace("option ", "")}</div>
                    </div>
                  </section>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>


      {
        reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {
        AlertShow &&
        <AlertComponent Alertcomp={Alertcomp} setAlertShow={setAlertShow} />
      }

    </div >
  )

}



const AnswerBlock3 = ({ index, DataType, DataValue }) => {
  return (
    <div
      className={DataType == "text" ? "col-6" : "col-3"}
      style={{
        display: 'flex',
        padding: "5px 10px",
        // borderBottom: "1px solid #999"
      }}
    >
      <div style={{ marginRight: "5px", cursor: "pointer" }}
      >


        <div style={{
          border: "2px solid black",
          borderRadius: "14px",
          width: "20px",
          height: "20px",
          display: "flex", justifyContent: "center", alignContent: "center",
          fontSize: "12px", fontWeight: "bold"
        }}>
          &nbsp;{index}&nbsp;
        </div>
      </div>


      <div className="passive-option2">
        <div style={{ fontSize: "10px" }}>
          {DataType == "text" &&
            `${DataValue}`
          }
        </div>
        {DataType == "image" &&
          <>
            <img
              src={DataValue}
              style={{
                // width: "120px",
                height: "50px",
                // objectFit: 'contain',
                // border: "0.5px solid #000"
              }}
            />
          </>
        }
        {DataType == "audio" &&
          <div>
            <a href={DataValue} target="_blank">
              <div style={{ cursor: "pointer" }} onClick={() => { }}>
                <div>
                  {/* <div style={{
                  position: "absolute"
                }}>
                  <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", width: "50px", height: "50px" }}>
                    <i className="fa fa-play " style={{ fontSize: "12px" }}></i>
                  </div>
                </div> */}
                  <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                    style={{ width: "20px", height: "20px" }}
                  />
                </div>
              </div>
            </a>
          </div>
        }
      </div>
    </div>


  )
}



const AnswerBlock = ({ option, index, DataType, DataValue, correct_answer }) => {
  return (
    <div
      className={DataType == "image" ? "col-6" : "col-12"}
      style={{
        display: 'flex',
        padding: "5px 10px",
        // borderBottom: "1px solid #999"
        borderLeft: option == correct_answer ? "2px solid green" : 0,
      }}
    >
      <div style={{ marginRight: "5px", cursor: "pointer" }}
      >
        <div style={{
          border: option == correct_answer ? "2px solid green" : "2px solid black",
          backgroundColor: option == correct_answer ? "green" : "#fff",
          color: option == correct_answer ? "#fff" : "#000",
          borderRadius: "14px",
          width: "20px",
          height: "20px", display: "flex", justifyContent: "center", alignContent: "center",
          fontSize: "12px", fontWeight: "bold"
        }}>
          &nbsp;{index}&nbsp;
        </div>
      </div>


      <div style={{ fontSize: "16px" }} className="passive-option2">
        {DataType == "text" &&
          `${DataValue}`
        }
        {DataType == "image" &&
          <>
            <img
              src={DataValue}
              style={{
                width: "120px",
                // height: "180px",
                // objectFit: 'contain',
                border: "0.5px solid #000"
              }}
            />
          </>
        }
        {DataType == "audio" &&
          <div>
            <a href={DataValue} target="_blank">
              <div style={{ cursor: "pointer" }} onClick={() => { }}>
                <div>

                  <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                    style={{ width: "20px", height: "20px" }}
                  />
                </div>
              </div>
            </a>
          </div>
        }
      </div>
    </div>


  )
}




const AnswerBlock2 = ({ index, DataType, DataValue }) => {
  return (
    <div
      className="col-6"
      style={{
        display: 'flex',
        padding: "5px 10px",
        // borderBottom: "1px solid #999"
      }}
    >
      <div style={{ marginRight: "5px", cursor: "pointer" }}
      >


        <div style={{
          border: "2px solid black",
          borderRadius: "14px",
          width: "20px",
          height: "20px", display: "flex", justifyContent: "center", alignContent: "center",
          fontSize: "12px", fontWeight: "bold"
        }}>
          &nbsp;{index}&nbsp;
        </div>
      </div>


      <div className="passive-option2">
        {DataType == "text" &&
          `${DataValue}`
        }
        {DataType == "image" &&
          <>
            <img
              src={DataValue}
              style={{
                width: "120px",
                // height: "180px",
                // objectFit: 'contain',
                border: "0.5px solid #000"
              }}
            />
          </>
        }
        {DataType == "audio" &&
          <div>
            <div style={{ cursor: "pointer" }} onClick={() => { }}>
              <div>
                <div style={{
                  position: "absolute"
                }}>
                  <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", width: "50px", height: "50px" }}>
                    <i className="fa fa-play " style={{ fontSize: "12px" }}></i>
                  </div>
                </div>
                <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                  style={{ width: "50px", height: "50px" }}
                />
              </div>
            </div>
          </div>
        }
      </div>
    </div>


  )
}




const AnswerBlock7 = ({ index, DataType, DataValue, oneQuestionData, correct_answer, option }) => {
  return (
    <div className={
      oneQuestionData.answer_media_type == "audio" ?
        "col-3" :
        DataType == "text" ?
          (oneQuestionData.question_description_type == "image" || oneQuestionData.question_media_type == "image") ?
            "col-12"
            :
            (oneQuestionData.option_1.length < 5 &&
              oneQuestionData.option_2.length < 5 &&
              oneQuestionData.option_3.length < 5 &&
              oneQuestionData.option_4.length < 5)
              ?
              "col-3"
              :

              (oneQuestionData.option_1.length < 12 &&
                oneQuestionData.option_2.length < 12 &&
                oneQuestionData.option_3.length < 12 &&
                oneQuestionData.option_4.length < 12)
                ?
                "col-6"
                : "col-12"
          :
          "col-6"}>
      {/* {oneQuestionData.answer_media_type} */}
      <div
        style={{
          borderLeft: 0,
          // borderBottom: correct_answer == option ? "2px solid green" : 0,
          padding: "0px 10px", display: "inline"
        }}>
        <div style={{ marginRight: "5px", cursor: "pointer", display: "inline-block", }}  >
          <div style={{
            // border: option == correct_answer ? "2px solid green" : "2px solid black",
            border: "1px solid black",
            backgroundColor: "#fff",
            color: "#000",
            // border: "1px solid black",
            // borderRadius: "20px", width: "30px", height: "30px", 
            borderRadius: "20px", width: "16px", height: "16px",
            display: "flex", justifyContent: "center", alignContent: "center",
            fontSize: "10px",
            // fontWeight: "bold"
          }}>
            &nbsp;{index}&nbsp;
          </div>
        </div>
        <div style={{ display: "inline-block", }}>
          <div style={{ fontSize: "16px" }}>{DataType == "text" && `${DataValue}`}</div>
          {DataType == "image" && <img src={DataValue} style={{ marginBottom: 5, height: "100px", width: "100%", objectFit: "contain", border: "0.5px solid #000", borderRadius: 2 }} />}
          {DataType == "audio" &&
            <div>
              <a href={DataValue} target="_blank">
                {/* <div style={{ cursor: "pointer" }} onClick={() => { }}>
                  <div>
                    <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                      style={{ width: "20px", height: "20px" }}
                    />
                  </div>
                </div> */}
              </a>
            </div>
          }
        </div>
      </div>
    </div>
  )
}